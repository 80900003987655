<template>

    <b-card-body class='d-flex flex-column' v-if='charts.length === 0' >
        <div class="d-flex flex-column justify-content-center">
            <div class="nodata-icon ml-auto mr-auto"></div>
            <p class="text-muted mt-1 ml-auto mr-auto text-center">
                Нет данных для отображения
            </p>
        </div>
    </b-card-body>    
    
    <b-row v-else >
        <b-col lg="4">
                            
                 <showAt breakpoint="mediumAndBelow">
                     <swiper v-if="charts.length > 0" ref="swiper" :options="swiperOptions" >
                        <swiper-slide v-for="(item, currentIndex) in charts" style="max-width: 90%; margin: 0 12px;">
                             <b-card  @click="changeSelectedTab( currentIndex )" :key="currentIndex"  no-body :bg-variant="selectedTabIndex === currentIndex ? '' : 'transparent' " :class=" selectedTabIndex === currentIndex ? 'is-picker' : 'is-picker with-border' ">
                                <b-card-body style="position: relative; padding: 20px 0 16px 24px">

                                    <div style="z-index: 100; position: relative;">
                                        <span class="text-muted" style="font-weight: 300; font-size: 14px;">№ контракта</span>
                                        <p style="font-size: 22px; font-weight: 600; margin-top: 4px;">
                                            {{ item.name }}
                                        </p>
                                    </div>

                                    <div style="position: absolute; top: 0%; right: 0; left: 60%; max-height: 100px; overflow: hidden; ">
                                        <statistic-card-with-area-chart
                                            color="success"
                                            statistic="123"
                                            icon="123"
                                            :chart-data="[item]"
                                        />
                                    </div>


                                </b-card-body>
                            </b-card>
                         </swiper-slide>
                    </swiper>
                 </showAt>
                
                <showAt breakpoint="large">
                    <div class="cards-block-view">
                        <b-card v-for="(item, currentIndex) in charts" @click="changeSelectedTab( currentIndex )" :key="currentIndex"  no-body :bg-variant="selectedTabIndex === currentIndex ? '' : 'transparent' " :class=" selectedTabIndex === currentIndex ? 'is-picker' : 'is-picker with-border' ">
                            <b-card-body style="position: relative; padding: 20px 0 16px 24px">

                                <div style="z-index: 100; position: relative;">
                                    <span class="text-muted" style="font-weight: 300; font-size: 14px;">№ контракта</span>
                                    <p style="font-size: 22px; font-weight: 600; margin-top: 4px;">
                                        {{ item.name }}
                                    </p>
                                </div>

                                <div style="position: absolute; top: 0%; right: 0; left: 60%; max-height: 100px; overflow: hidden; ">
                                    <statistic-card-with-area-chart
                                        color="success"
                                        statistic="123"
                                        icon="123"
                                        :chart-data="[item]"
                                    />
                                </div>


                            </b-card-body>
                        </b-card>
                    </div>
                </showAt>
            
            
        </b-col>
        <b-col lg="8">
            <graphic-widget v-if="charts.length > 0" :contract="charts[selectedTabIndex]" />
        </b-col>
    </b-row>
    
</template>

<script>

    import User from "@/modules/user/"
    import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

    import GraphicWidget from "./widgets/graphic"
    import VueApexCharts from 'vue-apexcharts'
     
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper' 
    import 'swiper/css/swiper.css' 
    
    export default {

        data() {
            return {
                selectedTabIndex: -1,
                charts: [],
                swiperOptions: {
                    init: true,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 0,
                    slidesOffsetBefore: 0
                },
            }
        },
        methods: {
            
            getChartDataForItem( item ) {
                return {
                    name: item.name,
                }
            },
            
            changeSelectedTab( value ) {
                this.selectedTabIndex = value;
            },
            
            get() {
                
                this.charts = [];
                
                this.$request.get("mining.graphics", {
                    type: this.$route.params.group === "miners" ? 2 : 1 
                }).then( result => {

                    Object.keys( result ).forEach(key => {
                        this.charts.push( result[key] ); 
                    });
                    
                    this.$nextTick(() => {
                        this.selectedTabIndex = 0;
                    });

                });
            }
            
        },
        components: {
            StatisticCardWithAreaChart,
            GraphicWidget,
            Swiper,
            SwiperSlide
        },
        watch: {
             "$route.params.group" : function( g ) {
                this.get();
            }, 
        },
        mounted() {
            this.get();
        }

    }

</script>