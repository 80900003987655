<template>
     <b-card class="graphic-widget" no-body>
     
        <showAt breakpoint="mediumAndAbove"> 
            
            <b-card-body class='pb-0 mb-0'>

                <b-card-title style="font-size: 20px; font-weight: 600; font-style: normal;">
                    Контракт №{{ contract.name }}
                </b-card-title> 

                <b-row class="mb-2" style="min-height: 80px; border: 1px solid #EBECF2; border-radius: 16px;">

                    <b-col md="3" class='mt-auto mb-auto d-flex justify-content-center'>
                        <div class='mt-auto mb-auto'>
                            <b-form-group class='mt-auto mb-auto' style="padding: 6px 8px; margin-right: 5px; background-color: transparent; border: 1px solid #EBECF2; border-radius: 16px;">
                                <b-button-group>
                                    <b-button @click="changeCurrency(User.currency)" :variant="ticker != 'BTC' ? 'active' : 'inactive' " size="sm" style="height: 36px;">{{ User.currency }}</b-button>
                                    <div class="delimiter"></div>
                                    <b-button @click="changeCurrency('BTC')" :variant="ticker === 'BTC' ? 'active' : 'inactive' " size="sm" style="height: 36px;">BTC</b-button>
                                </b-button-group>
                            </b-form-group>  
                        </div>  
                    </b-col>

                    <b-col class="mt-auto mb-auto with-seperator" md="4" style="border-right-width: 1px;">
                        <span class="text-muted">Добыто за 24 часа</span>
                        <p class="font-weight-bolder mb-0">
                            {{ getValueByTicker( contract['24h_profit']) }}
                            <span v-if="ticker !== 'BTC' ">{{ User.getSymbol() }}</span> 
                            <span v-else>BTC</span>
                        </p>
                    </b-col>

                    <b-col class="mt-auto mb-auto with-seperator" md="4" style="border-right-width: 1px;">
                        <span class="text-muted">Мощность контракта</span>
                        <p class="font-weight-bolder mb-0">
                            {{ contract.ths.formatMoney(0," "," ") }} 
                            TH/s
                        </p>
                    </b-col>

                </b-row>

            </b-card-body>
            
        </showAt>
         
        <showAt breakpoint="small">
            <b-card-body style="padding: 16px;">
                
                <b-card-title style="font-size: 20px; font-weight: 600; font-style: normal; margin-top: 14px;">
                    Контракт №{{ contract.name }}
                </b-card-title> 
                
                <b-button-group class="d-flex justify-content-between w-100">
                    <b-button @click="changeCurrency(User.currency)" :variant="ticker != 'BTC' ? 'active' : 'inactive' " size="sm" style="height: 36px;">{{ User.currency }}</b-button>
                    <div class="delimiter"></div>
                    <b-button @click="changeCurrency('BTC')" :variant="ticker === 'BTC' ? 'active' : 'inactive' " size="sm" style="height: 36px;">BTC</b-button>
                </b-button-group>
                
                <b-row style="margin-top: 20px;">
                    <b-col cols="6">
                        <span class="text-muted">Добыто за период</span>
                        <p class="font-weight-bolder mb-0">
                            {{ getValueByTicker( contract['profit']) }}
                            <span v-if="ticker !== 'BTC' ">{{ User.getSymbol() }}</span> 
                            <span v-else>BTC</span>
                        </p>
                    </b-col>
                    
                    <b-col cols="6">
                        <span class="text-muted">Добыто за 24 часа</span>
                        <p class="font-weight-bolder mb-0">
                            {{ getValueByTicker( contract['24h_profit']) }}
                            <span v-if="ticker !== 'BTC' ">{{ User.getSymbol() }}</span> 
                            <span v-else>BTC</span>
                        </p>
                    </b-col>
                    
                    <b-col cols="12" style="margin-top: 20px;">
                        <span class="text-muted">Мощность контракта</span>
                        <p class="font-weight-bolder mb-0">
                            {{ contract.ths.formatMoney(0," "," ") }} 
                            TH/s
                        </p>
                    </b-col>
                    
                </b-row>
                
            </b-card-body>
        </showAt>
         
        <chartjs-component-line-chart
            ref="chart"
            v-if="is_can_show_graph"
            :chartData="chartData"
            :options="chartOptions"
        />   
         
     </b-card>
    
       
         
</template>

<script>

    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import Blockchain from "@/modules/blockchain"
    import User from "@/modules/user/" 
    import ChartjsComponentLineChart from "./ChartjsComponentLineChart"

    const chartColors = {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#3ED085',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }

    let globalCurrencyTicker = User.currency;

    export default {

        data() {
                        
            return {
                
                User,
                
                ticker: User.currency,
                
                chartValues: [],
                chartLabels: [],     
                
                is_can_show_graph: false,
                
                chartOptions: {
                        responsive: true,
                        maintainAspectRatio: false,
                        backgroundColor: false,
                        locale: "ru-RU",
                        hover: {
                          mode: 'label',
                        },
                        tooltips: {
                            backgroundColor: "#141C40",
                            callbacks: {
//                                title: ( tooltipItem, data ) => {     
//
//                                    let index = tooltipItem[0].index;
//                                    let value = data.labels[index];
//                                    var date = new Date( value );
//
//                                    return new Intl.DateTimeFormat('ru', {  day: '2-digit', month: 'short', year: 'numeric' }).format(date);
//
//                                },
                                label: function( tooltipItem, data ) {

                                    if( globalCurrencyTicker === "BTC" ) {
                                        let dataset = data.datasets[tooltipItem.datasetIndex];
                                        let value = dataset.data[tooltipItem.index];

                                        return `${value} BTC`;
                                    }
                                
                                    let dataset = data.datasets[tooltipItem.datasetIndex];
                                    let value = ( Blockchain.tickers.values[User.currency].last * dataset.data[tooltipItem.index] ).formatMoney(0,' ',' ');

                                    return `${value} ${globalCurrencyTicker}`;

                                }
                            }
                        },
                        layout: {
                          padding: {
                            top: -15,
                            bottom: -25,
                            left: -15,
                          },
                        },

                        scales: {
                          xAxes: [
                            {
                                display: true,
                                scaleLabel: {
                                  display: true,
                                },
                                gridLines: {
                                  display: false,
                                },
                                ticks: {
                                  fontColor: "#9CA2B1",
                                },
                            },
                          ],
                          yAxes: [
                            {
                                display: true,
                                scaleLabel: {
                                  display: true,
                                },
                                gridLines: {
                                  display: true,
                                  borderDash: [5,5],
                                  color: "#EBECF2",
                                  zeroLineColor: "#EBECF2",
                                },
                                ticks: {
                                  fontColor: "#9CA2B1",
                                },
                            },
                          ],
                        },

                        legend: {
                          display: true,
                          position: 'top',
                          align: 'center',
                          labels: {
                            usePointStyle: true,
                            padding: 25,
                            boxWidth: 9,
                          },
                        },
                },
                
            }
        },
        props: {
            contract: {
                type: Object
            },
        },
        methods: {
            
            changeCurrency( value ) {
                this.ticker = value;
                globalCurrencyTicker = value;
                this.updateGraphForTicker();
            },
            
            getValueByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value;
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
                
            },
            
            updateGraphForTicker() {
                
                var tmp = this.contract.data ;
                var data = [];
                var labels = [];

                tmp.forEach(item => {
                    
                    if( this.ticker === "BTC" ) {
                       var y = item.y;
                    } else {
                       var y = Blockchain.tickers.values[User.currency].last * item.y;
                    }
                    
                    data.push( y );
                    labels.push(item. x);
                    
                });

                this.chartValues = data;
                this.chartLabels = labels;
                
            },
            
            update() {
                
                var tmp = this.contract.data ;
                var data = [];
                var labels = [];

                tmp.forEach(item => {
                    
                    if( this.ticker === "BTC" ) {
                       var y = item.y;
                    } else {
                       var y = Blockchain.tickers.values[User.currency].last * item.y;
                    }
                    
                    data.push( y );
                    labels.push(item. x);
                    
                });

                this.chartValues = data;
                this.chartLabels = labels;
                
                this.is_can_show_graph = true;
                
            },
        },
        components: {
            VueApexCharts,
            ChartjsComponentLineChart
        },
        
        computed: {
            
            successGradient() {

                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                var gradient = ctx.createLinearGradient(0, 0, 0, 400);

                gradient.addColorStop(0, 'rgba(62,208,133,0.5)');   
                gradient.addColorStop(1, 'rgba(62,208,133,0)');

                return gradient;
            },
                
            chartData() {
                return {
                    labels: this.chartLabels,
                    datasets: [
                        {
                            label: this.$i18n.locale == 'ru' ? 'Исторические данные' : 'Historical data',
                            borderColor: chartColors.successColorShade,
                            borderWidth: 2,
                            lineTension: 0.5,
                            pointStyle: 'circle',
                            backgroundColor: this.successGradient,
                            fill: true,
                            pointRadius: 1,
                            pointHoverRadius: 8,
                            pointHoverBorderWidth: 8,
                            pointBorderColor: 'transparent',
                            pointHoverBorderColor: $themeColors.white,
                            pointHoverBackgroundColor: $themeColors.success,
                            pointShadowOffsetX: 1,
                            pointShadowOffsetY: 1,
                            pointShadowBlur: 5,
                            pointShadowColor: chartColors.tooltipShadow,
                            cubicInterpolationMode: 'monotone',
                            tension: 0.4,
                            data: this.chartValues
                        },
                    ]
                }
            }
            
        },
        
        watch: {
            "contract" : function() {
                this.update();
            }
        },
        mounted() {
            this.update();

        }

    }

</script>