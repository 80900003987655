<template>
    
    <b-overlay
        :show="is_loading"
        blur="50"
        opacity="1"
        variant='transparent'
        spinner-variant="primary"
    >
        
        <div>
        
            <b-card  no-body style="border-radius: 0 !important; ">

                <b-card-body class="data-form mb-0 pb-0" style="padding: 16px;">

                    <b-button-group style="margin-bottom: 12px; width: 100%; justify-content: space-between;">
                        <template v-for="(status, index) in statuses">
                            <b-button  @click="filters.status = status" :variant="filters.status.value === status.value ? 'active' : 'inactive' " size="sm" style="height: 36px; white-space: nowrap;">{{ $t(status.display) }}</b-button>
                            <div v-if="index < statuses.length - 1" class="delimiter"></div>
                        </template>
                    </b-button-group>  

                   <b-form-group style="margin-bottom: 12px;">
                        <div class="d-flex justify-content-between">
                            <div style="flex-grow: 1">
                                <date-range-picker
                                    ref="date"
                                    :locale-data="$i18n.locale == 'ru' ? calendar_locale : calendar_en"
                                    :max-date="maxPickerDate"
                                    :singleDatePicker="false"
                                    :timePicker="false"
                                    :timePicker24Hour="true"
                                    :showWeekNumbers="false"
                                    :showDropdowns="false"
                                    :autoApply="false"
                                    :linkedCalendars="false"
                                    :ranges="false"
                                    v-model="filters.period"
                                    @update="get"
                                >
                                    <template v-slot:input="picker" style="min-width: 350px;">
                                        <span v-if="picker.startDate">{{ picker.startDate | date }} - {{ picker.endDate | date }}</span>
                                        <span v-else class="text-muted font-weight-normal">{{$t('statistics.period')}}</span>
                                    </template>

                                </date-range-picker>
                            </div>

                            <feather-icon @click="toggleDatePicker" icon="CalendarIcon" class="mt-auto mb-auto text-muted cursor-pointer" size="18"  />

                        </div>
                    </b-form-group>

                    <b-form-group style="margin-bottom: 12px;" >
                        <div class="d-flex justify-content-between" @click="togglePicker">
                            <div v-if=" contracts.raw.length === 0 " class="text-muted">{{$t('statistics.contracts')}}</div>
                            <div style="max-width: 155px; white-space: nowrap; text-overflow: ellipsis; overflow:hidden "> 
                                 {{ contracts.raw.join(',') }} 
                            </div>
                            <feather-icon icon="ChevronDownIcon" size="18" class="text-muted mt-auto mb-auto" />
                        </div>
                    </b-form-group>

                    <b-button-group style="margin-bottom: 12px; width: 100%; justify-content: space-between;">
                        <b-button @click=" ticker = User.currency " :variant="ticker != 'BTC' ? 'active' : 'inactive' " size="sm" style="height: 36px;">{{ User.currency }}</b-button>
                        <div class="delimiter"></div>
                        <b-button @click=" ticker = 'BTC' " :variant="ticker === 'BTC' ? 'active' : 'inactive' " size="sm" style="height: 36px;">BTC</b-button>
                    </b-button-group>

                </b-card-body>
                
                <b-card-body class='d-flex flex-column' v-if='statistic && statistic.sections.length === 0' >
                    <div class="d-flex flex-column justify-content-center">
                        <div class="nodata-icon ml-auto mr-auto"></div>
                        <p class="text-muted mt-1 ml-auto mr-auto text-center">
                            {{$t('tables.no-data')}}
                        </p>
                    </div>
                </b-card-body>

                <div v-else-if="statistic">
                    <div v-for="(section, sectionIndex) in statistic.sections" :key="sectionIndex">

                        <div class="group-header">
                            <span class="text-black">
                                {{ section.name }}
                            </span>
                        </div>

                        <template v-for="level in section.levels">

                            <div class="group-header bordered d-flex justify-content-between" style="top: 36px;">
                                <span class="text-black">
                                    {{ level.name }}
                                </span>
                                
                                <span class="text-muted font-weight-normal" style="margin-right: 25px;">
                                    +{{ getValueByTicker(level.summary) }}
                                    <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                                    <span v-else>₿</span>
                                </span>
                            </div>

                            <template v-for="(item, index) in level.items">
                                <b-card v-ripple @click="toggleCollapse(item)" :bg-variant=" item.collapsed ? 'primary-light' : '' " class="mb-0" no-body style="padding: 16px;">
                                    
                                    <div  class="d-flex justify-content-end">
                                        <b-link class="mr-auto" style="font-weight: 500;">{{ item.contract.guuid }}</b-link>
                                        <span class="text-black align-self-end">
                                            +{{ getValueByTicker(item.profit) }} 
                                            <span v-if="ticker !== 'BTC'" class='text-muted'>{{ User.getSymbol() }}</span> 
                                            <span v-else class='text-muted'>₿</span>
                                        </span>
                                        <div class=" mt-auto mb-auto d-flex align-self-end" style=" border-radius: 18px; margin-left: 12px; margin-right: -8px;">
                                            <feather-icon :icon=" item.collapsed ? 'ChevronsUpIcon' : 'ChevronsDownIcon' " :class=" item.collapsed ? 'text-primary m-auto'  : 'text-muted m-auto' " size="20" />
                                        </div>
                                    </div>
                                    
                                    
                                    <b-collapse id="collapse-4" v-model="item.collapsed" >
                                        <div style="padding: 13px 0; padding-right: 25px;">
                                            
                                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                                <span class="text-muted">{{$t('tables.total-profit')}}</span>
                                                <span>
                                                    {{ getValueByTicker( item.profit + item.energy + item.fee, 5 ) }} 
                                                    <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                                                    <span v-else>BTC</span>
                                                </span>
                                            </div>
                                            
                                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                                <span class="text-muted">  
                                                    <i18n path="tables.net-profit" tag="span">
                                                    </i18n>
                                                </span>
                                                <span>
                                                    {{ getValueByTicker( item.profit, 5 ) }} 
                                                    <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                                                    <span v-else>BTC</span>
                                                </span>
                                            </div>
                                            
                                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                                <span class="text-muted">{{$t('tables.energy')}}</span>
                                                <span>
                                                    {{ getValueByTicker( item.energy, 5 ) }} 
                                                    <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                                                    <span v-else>BTC</span>
                                                </span>
                                            </div>
                                            
                                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                                <span class="text-muted">{{$t('tables.service-fee')}}</span>
                                                <span>
                                                    {{ getValueByTicker( item.fee, 5 ) }} 
                                                    <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                                                    <span v-else>BTC</span>
                                                </span>
                                            </div>
                                            
                                            <div class="d-flex justify-content-between"  style="margin-bottom: 4px;">
                                                <span class="text-muted">{{$t('tables.complexity-network')}}</span>
                                                <span>{{ item.difficult.formatMoney(0, "", " ") }}</span>
                                            </div>
                                            
                                        </div>
                                    </b-collapse>
                      
                                    
                                </b-card>
                                
                                <div :class="{
                                    'separator' : true,
                                    'ml-0' : level.items.length - 1 === index
                                }"></div>
                                
                            </template>

                        </template>

                    </div>
                </div>
               

            </b-card>
                      
            
            
        </div>
        
        <b-modal 
            ref="picker" 
            id="picker"
            :title="$t('statistics.select-contracts')"
            :ok-title="$t('statistics.btns.apply')"
            centered 
            cancel-variant="gray"
            :cancel-title="$t('statistics.btns.cancel')"
            @show="pickerWillShow"
            @ok="addContractsToFilter"
            large
            
        >
            <b-overlay
                :show="contracts.is_loading"
                blur="50"
                opacity="1"
                variant='transparent'
                spinner-variant="primary"
                no-fade
            >
                <div style='min-height: 255px; max-height: 255px; overflow-y: scroll;'>
                    <b-list-group v-if="contracts.listOf.length > 0" flush>
                        <b-list-group-item class="d-flex cursor-pointer" @click="resetContractsFromFilter">
                            <div class="mt-auto mb-auto">
                                <p class="m-0">{{$t('statistics.all-contracts')}}</p>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, index) in contracts.listOf" :key="index" class="d-flex pb-0 pt-0 cursor-pointer" @click="toggleContractSelection(item)">
                            <div class="mt-auto mb-auto mr-1">
                                <b-form-checkbox name="contract" v-model="contracts.ids" :value="item.id" size="lg"  />
                            </div>
                            <div class="mt-auto mb-auto" style="flex-grow: 1;">
                                <p class="font-weight-bolder m-0">{{ item.guuid }}</p>
                            </div>
                            
                            <div class="mt-auto mb-auto align-self-end">
                                <p class="text-muted m-0" style="font-size: 12px;">{{ item.ths.formatMoney(0," ", " ") }} TH/s</p>
                            </div>
                            
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-overlay>
        </b-modal>
        
    </b-overlay>
</template>

<script>

    import User from "@/modules/user/"
    import flatPickr from 'vue-flatpickr-component'
    
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    import vSelect from 'vue-select'

    export default {

        data() {
            return {
                
                User,
                
                is_loading: false,
                is_load_more: false,
                
                statistic: null,
                
                ticker: User.currency,
                
                maxPickerDate: new Date(),
                
                isPickerActive: false,
                
                
                contracts: {
                    is_loading: false,
                    raw: [],
                    ids: [],
                    listOf: []
                },
                
                
                calendar_locale: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    weekLabel: 'Н',
                    customRangeLabel: 'Период',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авн', 'Сен', 'Окт', 'Нов', 'Дек'],
                    firstDay: 0
                },

                calendar_en: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'Н',
                    customRangeLabel: 'Period',
                    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    firstDay: 0
                },
                
                statuses: [
                    { value: "all", display: "statistics.statuses.all" },
                    { value: "inprogress", display: "statistics.statuses.inprogress" },
                    { value: "ended", display: "statistics.statuses.ended" }
                ],
                
                grouping: [
                    { value: "day", display: "statistics.grouping-type.day" },
                    { value: "week", display: "statistics.grouping-type.week" },
                    { value: "month", display: "statistics.grouping-type.month" },
                    { value: "", display: "statistics.grouping-type.without" },
                ],
                
                filters: {
                    period:{
                        startDate: null,
                        endDate: null
                    },
                    
                    ids: null,
                    
                    group: { 
                        value: "day", 
                        display: "statistics.grouping-type.day" 
                    },
                    
                    status: { value: "inprogress", display: "statistics.statuses.inprogress" }
                },
                
            }
        },
        methods: {
            
            toggleDatePicker( args ) {
                this.$refs.date.togglePicker(true);
                args.stopPropagation();
            },
            
            toggleCollapse(item) {
                
                if( item.collapsed === null || typeof(item.collapsed) === "undefined" ) {
                    item.collapsed = true;
                } else {
                    item.collapsed = !item.collapsed; 
                }
                
            },
            
            toggleContractSelection( item ) {
                
                if( this.contracts.ids.indexOf( item.id ) >= 0 ) {
                    this.contracts.ids.splice(this.contracts.ids.indexOf( item.id ), 1);
                } else {
                    this.contracts.ids.push( item.id );
                }
                
                this.contracts.ids = [... new Set( this.contracts.ids )];
                
            }, 
            
            resetContractsFromFilter( args ) {
                
                this.contracts.raw = [];
                this.contracts.ids = [];
                this.filters.ids = null;
                
                this.$bvModal.hide('picker');
                
            },
            
            addContractsToFilter( args ) {
                
                
                this.contracts.raw = [];
                
                this.contracts.listOf.forEach(item => {
                    if( this.contracts.ids.indexOf( item.id ) >= 0 ) {
                        this.contracts.raw.push( item.guuid ); 
                    }
                });
                
                if( this.contracts.ids.length > 0 ) {
                     this.filters.ids = this.contracts.ids;
                } else {
                     this.filters.ids = null;
                }
                 
            },
            
            pickerWillShow( args ) {
                if( this.contracts.listOf.length > 0 ) return;
                this.contracts.is_loading = true;
                this.$request.get("contracts.list", {
                    simpled: 1,
                    type: this.$route.params.group === "miners" ? 2 : 1 
                }).then( result => {
                    this.contracts.is_loading = false;
                    this.contracts.listOf = result;
                });
            },
            
            togglePicker() {
                
                this.$bvModal.show('picker');
                
                this.isPickerActive = !this.isPickerActive;
            },
            
            getEnergyByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(6);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(6, "", " ");
                
            },
            
            getValueByTicker( value, decimal = 2 ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(6);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(decimal, "", " ");
                
            },
            
            handleScroll( e ) {            
                                
                if(!this.statistic) return;
                if(!this.statistic.has_more_pages) return;
                if( this.is_loading ) return;
                
                let el = e.target;
               
                if( el.scrollTop + el.offsetHeight > ( el.scrollHeight - 100 ) ) {
                    
                  let params = this.params;
                  
                  params['page'] = this.statistic.current_page + 1;
                  
                  this.is_loading = true;
                  
                  this.$request.get("mining.statistic", params ).then( result => {
                       this.statistic.has_more_pages = result.has_more_pages;
                       this.statistic.current_page = result.current_page;
                       this.statistic.sections.push( ...result.sections );
                       this.is_loading = false;                       
                  });
                  
                }
                
            },
            
            get() {
                
                this.is_loading = true;
                this.$request.get("mining.statistic", this.params ).then(result => {
                    
                     this.$nextTick(() => {
                         this.statistic = result;
                         setTimeout(() => {
                            document.getElementById('table-detalization').parentNode.removeEventListener("scroll", this.loadMoreItems );
                            document.getElementById('table-detalization').parentNode.addEventListener("scroll", this.loadMoreItems );
                         }, 155 );
                     });
                     
                     this.is_loading = false;
                });
                
            },
            
            formatDate(val) {
              return val ? new Intl.DateTimeFormat('ru', { day: 'numeric',  year: '2-digit', month: 'numeric' }).format(val) : ''
            }
        },
        filters: {
            date(val) {
              return val ? new Intl.DateTimeFormat('ru', { day: 'numeric',  year: '2-digit', month: 'numeric' }).format(val) : ''
            }
        },
      
        computed: {
            params() {
                
                let period = null;
                let type = 1;
                
                switch( this.$route.params.group ) {
                    case "cloud" : type = 1; break;
                    case "miners" : type = 2; break;
                }
                
                if( this.filters.period.startDate && this.filters.period.endDate ) {
                    period = {};
                    period['start'] = this.filters.period.startDate  ;
                    period['end'] = this.filters.period.endDate  ;
                }
                
                return {
                    period: period,
                    ids: this.filters.ids,
                    group: this.filters.group.value,
                    status: this.filters.status.value,
                    type: type
                }
            }
        },
        
        created() {
            document.getElementsByClassName('content-wrapper')[0].addEventListener("scroll", this.handleScroll );
        },
        
        destroyed() {
            document.getElementsByClassName('content-wrapper')[0].removeEventListener("scroll", this.handleScroll );
        },
        
        components: {
            flatPickr,
            DateRangePicker,
            vSelect
        },
        watch: {
            
            "$route.params.group" : function( g ) {
                this.get();
                this.this.contracts.listOf = [];
            },
            
            "filters.group" : function() {
                this.get();
            },
            
            "filters.ids" : function() {
                this.get();
            },
            
            "filters.status" : function() {
                this.get();
            },
            
        },
        mounted() {

            this.get();           

        }

    }

</script>

<style lang="scss">
    
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/vue-select.scss';

    .reportrange-text {
        background-color: transparent !important;
        border: none !important;
    }
    
    .vue-daterange-picker {
        flex-grow: 1;
        width: 100%;
    }
    
    [dir] .daterangepicker td.in-range {
        background-color: #E4EAFD !important;
    }
    
    .dark-layout .daterangepicker td.in-range {
        background-color: #262642 !important;
        color: #FFF;
    }
    
    [dir] .daterangepicker td.active, [dir] .daterangepicker td.active:hover {
        background-color: #426FF6 !important;
    }
    
    [dir=ltr] .daterangepicker td.start-date {
        border-radius: 22px 0 0 22px;
    }
    
    [dir=ltr] .daterangepicker td.end-date {
        border-radius: 0 22px 22px 0;
    }
    
    [dir] .daterangepicker {
        border: none;
        border-radius: 16px;
        box-shadow: 0px 6px 40px rgba(99, 109, 137, 0.15);
    }
    
    .reportrange-text {
        margin: 0 !important;
        padding: 0 !important;
    }
    
    [dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::before, [dir=ltr] .input-group-lg .custom-checkbox .custom-control-label::before {
        left: 0px;
    }
    
    [dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::after, [dir=ltr] .input-group-lg .custom-checkbox .custom-control-label::after {
        left: -2px;
    }
    
    .dark-layout .daterangepicker .drp-buttons {
            border-top: 1px solid rgba(255,255,255,0.1);
    }
    
    .dark-layout .daterangepicker .calendar-table .next span, .dark-layout .daterangepicker .calendar-table .prev span {
        border-color: #fff;    
    }
    
    
</style>

