<template>
    
    <b-tabs class="large-title">
        
        <b-tab :title="$t('statistics.detalization')">
            <showAt breakpoint="large">
                 <detalization />
            </showAt>
            <showAt breakpoint="medium">
                <detalization-tablet />
            </showAt>
            <showAt breakpoint="small">
                 <detalization-mobile />
            </showAt>
        </b-tab>
        <b-tab :title="$t('statistics.yield-charts')">
            <graphics />
        </b-tab>
        
    </b-tabs>

</template>

<script>

    import User from "@/modules/user"

    import detalization from "./detalization"
    import detalizationTablet from "./detalization.tablet"
    import detalizationMobile from "./detalization.mobile"
    
    import graphics from "./graphics"

    export default {

        data() {
            return {
                User,
                statistic: null,
                charts: [],
                statisticTableFields: [
                    { key: "contract", label: "Контракт" },
                    { key: "difficult", label: "Сложность сети" },
                    { key: "profit", label: "Добыто" },
                    { key: "ths", label: "Мощность" },
                    { key: "timestamp", label: "Время" },
                ]
            }
        },
        methods: {
            
            
            linkGen( pageNum ) {
              return `${this.$route.path}?page=${pageNum}`;                  
            },
            
        },
        components: {
            
            detalization,
            detalizationTablet,
            detalizationMobile,
            
            graphics
            
        },
        watch: {
           
        },
        mounted() {

           if( User.currency === "BTC" ) {
               User.currency = "USD";
           }

        }

    }

</script>